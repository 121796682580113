import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promoCode: '',
  promoCodeError: false,
  promoCodeApplied: false,
  promoCodeLimitExceeded: false,
};

export const promoCodeSlice = createSlice({
  name: 'promoCodeInfo',
  initialState,
  reducers: {
    updatePromoCode: (state, action) => {
      state.promoCode = action.payload;
    },
    updatePromoCodeError: (state, action) => {
      state.promoCodeError = action.payload;
    },
    updatePromoCodeApplied: (state, action) => {
      state.promoCodeApplied = action.payload;
    },
    updatePromoCodeLimitExceeded: (state, action) => {
      state.promoCodeLimitExceeded = action.payload;
    },
  },
});

export const { actions } = promoCodeSlice;

const getPromoCode = (state) => state.promoCodeInfo.promoCode;
const getPromoCodeError = (state) => state.promoCodeInfo.promoCodeError;
const getPromoCodeApplied = (state) => state.promoCodeInfo.promoCodeApplied;
const getPromoCodeLimitExceeded = (state) => state.promoCodeInfo.promoCodeLimitExceeded;

export const selectors = {
  getPromoCode,
  getPromoCodeError,
  getPromoCodeApplied,
  getPromoCodeLimitExceeded,
};

export default promoCodeSlice.reducer;
