import { combineReducers, configureStore } from '@reduxjs/toolkit';
import contactInfoReducer from './slices/contactInfo.slice';
import shippingAddressReducer from './slices/shippingAddress.slice';
import billingAddressReducer from './slices/billingAddress.slice';
import promoCodeReducer from './slices/promoCode.slice';

const combinedReducers = combineReducers({
  contactInfo: contactInfoReducer,
  shippingAddress: shippingAddressReducer,
  billingAddress: billingAddressReducer,
  promoCodeInfo: promoCodeReducer,
});

export function makeStore(preloadedState) {
  return configureStore({
    reducer: combinedReducers,
    preloadedState,
  });
}

const store = makeStore();

export default store;
