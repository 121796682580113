import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  address: '',
  addressDetails: '',
  city: '',
  country: 'United Kingdom',
  postcode: '',
  shippingBillingSameAddress: true,
};

export const shippingAddressSlice = createSlice({
  name: 'shippingAddress',
  initialState,
  reducers: {
    updateAddress: (state, action) => {
      state.address = action.payload;
    },
    updateAddressDetails: (state, action) => {
      state.addressDetails = action.payload;
    },
    updateCity: (state, action) => {
      state.city = action.payload;
    },
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updatePostcode: (state, action) => {
      state.postcode = action.payload;
    },
    updateShippingBillingSameAddress: (state, action) => {
      state.shippingBillingSameAddress = action.payload;
    },
  },
});

export const { actions } = shippingAddressSlice;

const getAddress = (state) => state.shippingAddress.address;
const getAddressDetails = (state) => state.shippingAddress.addressDetails;
const getCity = (state) => state.shippingAddress.city;
const getCountry = (state) => state.shippingAddress.country;
const getPostcode = (state) => state.shippingAddress.postcode;
const getShippingBillingSameAddress = (state) => state.shippingAddress.shippingBillingSameAddress;

export const selectors = {
  getAddress,
  getAddressDetails,
  getCity,
  getCountry,
  getPostcode,
  getShippingBillingSameAddress,
};

export default shippingAddressSlice.reducer;
