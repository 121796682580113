import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  address: '',
  addressDetails: '',
  city: '',
  country: '',
  postcode: '',
};

export const billingAddressSlice = createSlice({
  name: 'billingAddress',
  initialState,
  reducers: {
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateAddress: (state, action) => {
      state.address = action.payload;
    },
    updateAddressDetails: (state, action) => {
      state.addressDetails = action.payload;
    },
    updateCity: (state, action) => {
      state.city = action.payload;
    },
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updatePostcode: (state, action) => {
      state.postcode = action.payload;
    },
  },
});

export const { actions } = billingAddressSlice;

const getFirstName = (state) => state.billingAddress.firstName;
const getLastName = (state) => state.billingAddress.lastName;
const getAddress = (state) => state.billingAddress.address;
const getAddressDetails = (state) => state.billingAddress.addressDetails;
const getCity = (state) => state.billingAddress.city;
const getCountry = (state) => state.billingAddress.country;
const getPostcode = (state) => state.billingAddress.postcode;

export const selectors = {
  getFirstName,
  getLastName,
  getAddress,
  getAddressDetails,
  getCity,
  getCountry,
  getPostcode,
};

export default billingAddressSlice.reducer;
