'use client';

import { useEffect } from 'react';
import { getBrazeFlag } from 'harness-react';
import { initializeBraze } from 'braze-react';

export default function BrazeProvider({ children, brazeConfig }) {
  const brazeEnabled = getBrazeFlag();

  useEffect(() => {
    const startBraze = async () => {
      await initializeBraze(brazeConfig);
    };

    if (brazeEnabled) {
      startBraze();
    }
  }, [brazeEnabled]);

  return children;
}
