import(/* webpackMode: "eager" */ "/app/apps/shop/src/components/layout/brazeProvider.jsx");
import(/* webpackMode: "eager" */ "/app/apps/shop/src/components/layout/harnessFeatureFlagProvider.jsx");
import(/* webpackMode: "eager" */ "/app/apps/shop/src/components/layout/reduxProvider.jsx");
import(/* webpackMode: "eager" */ "/app/apps/shop/src/styles/globals-tw.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/(tw)/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/Matter-Light.woff2\",\"weight\":\"300\",\"variable\":\"--font-matter-light\"}],\"variableName\":\"MatterLight\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/(tw)/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/Matter-Medium.woff2\",\"weight\":\"400\",\"variable\":\"--font-matter-medium\"}],\"variableName\":\"MatterMedium\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/(tw)/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/Matter-Regular.woff2\",\"weight\":\"500\",\"variable\":\"--font-matter-regular\"}],\"variableName\":\"MatterRegular\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/(tw)/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../../public/fonts/Matter-SemiBold.woff2\",\"weight\":\"600\",\"variable\":\"--font-matter-semibold\"}],\"variableName\":\"MatterSemibold\"}");
import(/* webpackMode: "eager" */ "/app/packages/shared/ui/theme/theme.js")