import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  acknowledgePrivacyChecked: false,
  marketingConsentChecked: false,
};

export const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updateAcknowledgePrivacyChecked: (state, action) => {
      state.acknowledgePrivacyChecked = action.payload;
    },
    updateMarketingConsentChecked: (state, action) => {
      state.marketingConsentChecked = action.payload;
    },
  },
});

export const { actions } = contactInfoSlice;

const getFirstName = (state) => state.contactInfo.firstName;
const getLastName = (state) => state.contactInfo.lastName;
const getEmail = (state) => state.contactInfo.email;
const getPhone = (state) => state.contactInfo.phone;
const getAcknowledgePrivacyChecked = (state) => state.contactInfo.acknowledgePrivacyChecked;
const getMarketingConsentChecked = (state) => state.contactInfo.marketingConsentChecked;

export const selectors = {
  getFirstName,
  getLastName,
  getEmail,
  getPhone,
  getAcknowledgePrivacyChecked,
  getMarketingConsentChecked,
};

export default contactInfoSlice.reducer;
